import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CMSWrapper from '@ic-hosting/ich-contentful-cms-wrapper';
import { updateData } from '../data/contentful/actions';
import Layout from '../components/layout';
import ClassPanel from '../components/Class/panel'

// import CMSWrapper, { ImageEditor, TypeEditor } from'../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

class LocationTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.state;
  }

  componentWillMount() {

  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData();
  }

  render() {
    const {
      // data,
      pageContext,
      location,
      content,
    } = this.props;
    // const post = data.markdownRemark;
    const { slug } = pageContext;
    try {

    
    const instructorData = content.data.instructor[slug];
    const placeholder = content.data.assets.Placeholder;
    return (
      <Layout location={location} title={instructorData.name}>
        <section className="inner-header divider parallax layer-overlay overlay-dark-5" data-bg-img={
          instructorData.image ? instructorData.image.size({
            width: 1500,
            fit: 'fill',
            focus: 'face'
          })
        : null }
        >
          <CMSWrapper {...instructorData}>
          <div className="container pt-100 pb-50">
            <div className="section-content pt-100">
              <div className="row"> 
                <div className="col-md-12">
                  <h3 className="title text-white"><span className="text-theme-colored">Instructor: </span>{instructorData.name}</h3>
                </div>
              </div>
            </div>
          </div>
          </CMSWrapper>
        </section>
        <section>
          <div className="container pb-30">
            <div className="section-content">
              <div className="row">
                <div className="col-md-5 pr-60 pr-sm-0 mt-sm-60">
                  <div className="widget">
                    <h4 className="widget-title line-bottom text-uppercase bg-theme-colored p-10 text-white">Instructor Details</h4>
                    <div className="opening-hours">
                      <ul className="list-border">
                        <li className="clearfix"> <span> Grade :  </span>
                          <div className="value pull-right flip"> {instructorData.grade}</div>
                        </li>
                        <li className="clearfix"> <span> Contact :</span>
                          <div className="value pull-right flip" dangerouslySetInnerHTML={{__html: instructorData.contactDetails}} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  {instructorData.achievements ? (
                    <div className="widget">
                      <h4 className="widget-title line-bottom text-uppercase bg-theme-colored p-10 text-white">Achievements</h4>
                      <div className="latest-posts" dangerouslySetInnerHTML={{__html: instructorData.achievements}} />
                    </div>
                  )
                  : null }
                </div>
                <div className="col-md-7">
                  <div className="single-class-details">
                    <img src={
                      instructorData.image ? instructorData.image.size({
                        width: 650,
                        height: 441,
                        fit: 'fill',
                        focus: 'face'
                      })
                    : placeholder.size({
                      width: 650,
                      height: 440
                    })
                    } className="img-fullwidth" alt="" />
                    <h5>Information</h5>
                    <div dangerouslySetInnerHTML={{__html: instructorData.information}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
    } catch (e) {
      return null;
    }
  };
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});



export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationTemplate);
